// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---plugin-templates-landing-page-index-js": () => import("/opt/build/repo/plugin/Templates/LandingPage/index.js" /* webpackChunkName: "component---plugin-templates-landing-page-index-js" */),
  "component---plugin-templates-profile-page-index-js": () => import("/opt/build/repo/plugin/Templates/ProfilePage/index.js" /* webpackChunkName: "component---plugin-templates-profile-page-index-js" */)
}

exports.data = () => import("/opt/build/repo/.cache/data.json")

